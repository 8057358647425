import $ from 'jquery';
import 'justifiedGallery';
import 'jquery-colorbox';

/** import local dependencies */
import './webflow.js';


/**
 * Anonymous function is autoexecutable.
 */
(function () {

  // set click listener to cookie bar
  let cookieBar = document.querySelector('.cookies');
  let cookieButton = document.querySelector('.cookie-button');
  if( cookieBar !== null ) {
    let hiddeCookieBar = (event) => {
      event.preventDefault();
      window.localStorage.setItem('cookie_bar', true);
      cookieBar.classList.remove('active');
    };

    let initialCookieBar = () => {
      let ls_cookie_bar = JSON.parse(window.localStorage.getItem('cookie_bar'));
      if(ls_cookie_bar === false || ls_cookie_bar === null ){
        cookieBar.classList.add('active');
      }
    };

    cookieButton.addEventListener('click', hiddeCookieBar);
    window.addEventListener('load', initialCookieBar);
  }

  if($('#gallery').length > 0){

    $('#gallery').justifiedGallery({
      lastRow : 'justify',
      rowHeight : 200,
      margins : 16,
      randomize: false,
      //maxRowHeight: '200%',
      captions: false
    }).on('jg.complete', function(e){
      $(this).find('a').colorbox({
        maxWidth : '80%',
        maxHeight : '80%',
        opacity : 0.8,
        transition : 'elastic',
        current : ''
      });
    });
  }

  let initialHash = location.hash;
  const $html = document.querySelector('html');

  function openModal(hash){
    closeModal();
    let $el = document.querySelector(hash);
    if ( $el && $el.classList.contains('modal') ) {
      $html.classList.add('overflow');
      $el.classList.add('active');
    }
  }

  function closeModal(){
    document.querySelectorAll('.modal').forEach($el => {
      $html.classList.remove('overflow');
      $el.classList.remove('active');
    });
  }

  function cleanHash(){
    history.pushState(null, null, '#');
  }

  window.addEventListener('hashchange', () => {
    let hash = window.location.hash;
    //console.log(hash);
    if ( hash !== '' ) {
      openModal(hash);
    } else {
      closeModal();
    }
  });


  if (initialHash.length > 0) {
    openModal(initialHash);
  }

  var $anchorLinks = document.querySelectorAll('a[href^="#"]');
  $anchorLinks.forEach(function (link) {
    if (link.hash.length > 0) {

        link.addEventListener('click', function (ev) {
          let container = document.getElementById(link.hash.substring(1));
          if(container.classList.contains('modal')){
            ev.stopPropagation();
            window.location.hash = link.hash  
          }
          
        });
    }

  });

  document.addEventListener('keydown', function(e) {
    if (e.which === 27) {
      closeModal();
      cleanHash();
    }
  });

  if($('.modal-close').length > 0){
    $('.modal-close').on('click', function(e){
      e.preventDefault;
      closeModal();
      cleanHash();
    });
  }

  window.onclick = function(event) {
    if(event.target.classList.contains('modal')){
      closeModal();
      cleanHash();
    }
  }

  let promoBanner = document.querySelector('.promo_banner');
  console.log('promoBanner', promoBanner);
  let promoButton = document.querySelector('.promo_button');
  if( promoBanner !== null ) {
    let hiddePromoBanner = (event) => {
      event.preventDefault();
      window.sessionStorage.setItem('promo_banner', true);
      promoBanner.classList.remove('active');
    };

    let initialPromoBanner = () => {
      let ss_promo_banner = JSON.parse(window.sessionStorage.getItem('promo_banner'));
      if(ss_promo_banner === false || ss_promo_banner === null ){
        promoBanner.classList.add('active');
      }
    };

    promoButton.addEventListener('click', hiddePromoBanner);
    window.addEventListener('load', initialPromoBanner);
  }



})();
